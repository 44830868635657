exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arculati-elemek-js": () => import("./../../../src/pages/arculati-elemek.js" /* webpackChunkName: "component---src-pages-arculati-elemek-js" */),
  "component---src-pages-blog-az-izeltlabuak-hu-hozzajarulasa-az-european-stag-beetle-monitoring-projecthez-js": () => import("./../../../src/pages/blog/az-izeltlabuak-hu-hozzajarulasa-az-european-stag-beetle-monitoring-projecthez.js" /* webpackChunkName: "component---src-pages-blog-az-izeltlabuak-hu-hozzajarulasa-az-european-stag-beetle-monitoring-projecthez-js" */),
  "component---src-pages-blog-az-izeltlabuak-hu-kozremukodese-az-european-moth-nights-2023-esemenyen-js": () => import("./../../../src/pages/blog/az-izeltlabuak-hu-kozremukodese-az-european-moth-nights-2023-esemenyen.js" /* webpackChunkName: "component---src-pages-blog-az-izeltlabuak-hu-kozremukodese-az-european-moth-nights-2023-esemenyen-js" */),
  "component---src-pages-blog-ejszakai-lepke-megfigyelesek-a-national-moth-week-2023-esemenyen-js": () => import("./../../../src/pages/blog/ejszakai-lepke-megfigyelesek-a-national-moth-week-2023-esemenyen.js" /* webpackChunkName: "component---src-pages-blog-ejszakai-lepke-megfigyelesek-a-national-moth-week-2023-esemenyen-js" */),
  "component---src-pages-blog-felhasznalasi-feltetelek-beallitasainak-valtozasa-js": () => import("./../../../src/pages/blog/felhasznalasi-feltetelek-beallitasainak-valtozasa.js" /* webpackChunkName: "component---src-pages-blog-felhasznalasi-feltetelek-beallitasainak-valtozasa-js" */),
  "component---src-pages-blog-hatarozasi-rendszer-tervezett-atalakitasa-js": () => import("./../../../src/pages/blog/hatarozasi-rendszer-tervezett-atalakitasa.js" /* webpackChunkName: "component---src-pages-blog-hatarozasi-rendszer-tervezett-atalakitasa-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mme-2023-ev-lekeje-adatgyujtesi-verseny-fecskefarku-lepke-js": () => import("./../../../src/pages/blog/mme-2023-ev-lekeje-adatgyujtesi-verseny-fecskefarku-lepke.js" /* webpackChunkName: "component---src-pages-blog-mme-2023-ev-lekeje-adatgyujtesi-verseny-fecskefarku-lepke-js" */),
  "component---src-pages-blog-onkentes-termeszeti-adatgyujtok-talalkozoja-2024-js": () => import("./../../../src/pages/blog/onkentes-termeszeti-adatgyujtok-talalkozoja-2024.js" /* webpackChunkName: "component---src-pages-blog-onkentes-termeszeti-adatgyujtok-talalkozoja-2024-js" */),
  "component---src-pages-blog-ujdonsag-megjelenitett-elemek-szamanak-allitasa-js": () => import("./../../../src/pages/blog/ujdonsag-megjelenitett-elemek-szamanak-allitasa.js" /* webpackChunkName: "component---src-pages-blog-ujdonsag-megjelenitett-elemek-szamanak-allitasa-js" */),
  "component---src-pages-blog-vegyel-reszt-a-national-moth-week-2024-esemenyen-js": () => import("./../../../src/pages/blog/vegyel-reszt-a-national-moth-week-2024-esemenyen.js" /* webpackChunkName: "component---src-pages-blog-vegyel-reszt-a-national-moth-week-2024-esemenyen-js" */),
  "component---src-pages-blog-vegyel-reszt-az-europai-lepkek-ejszakaja-2024-esemenyen-js": () => import("./../../../src/pages/blog/vegyel-reszt-az-europai-lepkek-ejszakaja-2024-esemenyen.js" /* webpackChunkName: "component---src-pages-blog-vegyel-reszt-az-europai-lepkek-ejszakaja-2024-esemenyen-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-irasok-bencepoloska-marvanyospoloska-js": () => import("./../../../src/pages/irasok/bencepoloska-marvanyospoloska.js" /* webpackChunkName: "component---src-pages-irasok-bencepoloska-marvanyospoloska-js" */),
  "component---src-pages-irasok-csokospoloska-js": () => import("./../../../src/pages/irasok/csokospoloska.js" /* webpackChunkName: "component---src-pages-irasok-csokospoloska-js" */),
  "component---src-pages-irasok-js": () => import("./../../../src/pages/irasok.js" /* webpackChunkName: "component---src-pages-irasok-js" */),
  "component---src-pages-irasok-lovagbodobacsok-js": () => import("./../../../src/pages/irasok/lovagbodobacsok.js" /* webpackChunkName: "component---src-pages-irasok-lovagbodobacsok-js" */),
  "component---src-pages-irasok-papmacskak-hatarozasa-js": () => import("./../../../src/pages/irasok/papmacskak-hatarozasa.js" /* webpackChunkName: "component---src-pages-irasok-papmacskak-hatarozasa-js" */),
  "component---src-pages-irasok-poloskak-a-lakasban-js": () => import("./../../../src/pages/irasok/poloskak-a-lakasban.js" /* webpackChunkName: "component---src-pages-irasok-poloskak-a-lakasban-js" */),
  "component---src-pages-irasok-specieshelp-js": () => import("./../../../src/pages/irasok/specieshelp.js" /* webpackChunkName: "component---src-pages-irasok-specieshelp-js" */),
  "component---src-pages-linkek-js": () => import("./../../../src/pages/linkek.js" /* webpackChunkName: "component---src-pages-linkek-js" */),
  "component---src-pages-megjelenesek-2018-halyomorpha-halys-js": () => import("./../../../src/pages/megjelenesek/2018-halyomorpha-halys.js" /* webpackChunkName: "component---src-pages-megjelenesek-2018-halyomorpha-halys-js" */),
  "component---src-pages-megjelenesek-2023-stagbeetlemonitoring-js": () => import("./../../../src/pages/megjelenesek/2023-stagbeetlemonitoring.js" /* webpackChunkName: "component---src-pages-megjelenesek-2023-stagbeetlemonitoring-js" */),
  "component---src-pages-megjelenesek-2023-wierl-bettina-a-gerecse-nappali-lepke-faunajanak-vizsgalata-labatlan-tersegeben-js": () => import("./../../../src/pages/megjelenesek/2023-wierl-bettina-a-gerecse-nappali-lepke-faunajanak-vizsgalata-labatlan-tersegeben.js" /* webpackChunkName: "component---src-pages-megjelenesek-2023-wierl-bettina-a-gerecse-nappali-lepke-faunajanak-vizsgalata-labatlan-tersegeben-js" */),
  "component---src-pages-utmutatok-adatok-atadasa-tudomanyos-es-termeszetvedelmi-celra-js": () => import("./../../../src/pages/utmutatok/adatok-atadasa-tudomanyos-es-termeszetvedelmi-celra.js" /* webpackChunkName: "component---src-pages-utmutatok-adatok-atadasa-tudomanyos-es-termeszetvedelmi-celra-js" */),
  "component---src-pages-utmutatok-adatok-megosztasa-a-gbif-platformon-js": () => import("./../../../src/pages/utmutatok/adatok-megosztasa-a-gbif-platformon.js" /* webpackChunkName: "component---src-pages-utmutatok-adatok-megosztasa-a-gbif-platformon-js" */),
  "component---src-pages-utmutatok-felhasznalasi-feltetelek-beallitasai-js": () => import("./../../../src/pages/utmutatok/felhasznalasi-feltetelek-beallitasai.js" /* webpackChunkName: "component---src-pages-utmutatok-felhasznalasi-feltetelek-beallitasai-js" */),
  "component---src-pages-utmutatok-felhasznalo-talalatainak-rendszertani-kategoriak-szerinti-attekintese-js": () => import("./../../../src/pages/utmutatok/felhasznalo-talalatainak-rendszertani-kategoriak-szerinti-attekintese.js" /* webpackChunkName: "component---src-pages-utmutatok-felhasznalo-talalatainak-rendszertani-kategoriak-szerinti-attekintese-js" */),
  "component---src-pages-utmutatok-hatarozoi-jogkor-bemutatasa-js": () => import("./../../../src/pages/utmutatok/hatarozoi-jogkor-bemutatasa.js" /* webpackChunkName: "component---src-pages-utmutatok-hatarozoi-jogkor-bemutatasa-js" */),
  "component---src-pages-utmutatok-hatarozok-tamogatasa-js": () => import("./../../../src/pages/utmutatok/hatarozok-tamogatasa.js" /* webpackChunkName: "component---src-pages-utmutatok-hatarozok-tamogatasa-js" */),
  "component---src-pages-utmutatok-izeltlabuak-hu-lehetosegeinek-ismertetese-js": () => import("./../../../src/pages/utmutatok/izeltlabuak-hu-lehetosegeinek-ismertetese.js" /* webpackChunkName: "component---src-pages-utmutatok-izeltlabuak-hu-lehetosegeinek-ismertetese-js" */),
  "component---src-pages-utmutatok-js": () => import("./../../../src/pages/utmutatok.js" /* webpackChunkName: "component---src-pages-utmutatok-js" */),
  "component---src-pages-utmutatok-rolunk-js": () => import("./../../../src/pages/utmutatok/rolunk.js" /* webpackChunkName: "component---src-pages-utmutatok-rolunk-js" */),
  "component---src-pages-utmutatok-talalat-rogzitese-js": () => import("./../../../src/pages/utmutatok/talalat-rogzitese.js" /* webpackChunkName: "component---src-pages-utmutatok-talalat-rogzitese-js" */),
  "component---src-pages-utmutatok-tamogatas-js": () => import("./../../../src/pages/utmutatok/tamogatas.js" /* webpackChunkName: "component---src-pages-utmutatok-tamogatas-js" */),
  "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2021-js": () => import("./../../../src/pages/utmutatok/tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2021.js" /* webpackChunkName: "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2021-js" */),
  "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2022-js": () => import("./../../../src/pages/utmutatok/tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2022.js" /* webpackChunkName: "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2022-js" */),
  "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2023-js": () => import("./../../../src/pages/utmutatok/tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2023.js" /* webpackChunkName: "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2023-js" */),
  "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2024-js": () => import("./../../../src/pages/utmutatok/tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2024.js" /* webpackChunkName: "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-2024-js" */),
  "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-js": () => import("./../../../src/pages/utmutatok/tudomanyos-es-ismeretterjeszto-celu-megjelenesek.js" /* webpackChunkName: "component---src-pages-utmutatok-tudomanyos-es-ismeretterjeszto-celu-megjelenesek-js" */)
}

